.div-welcome {
  margin-top: 10px;
  align-items: center;
  text-align: center;
}

.div-enter {
  padding: 10px;
  align-items: center;
  text-align: center;
}

.div-enter:hover {
  cursor: pointer;
}
