.tale-panel-h1 {
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #473b5a; /* 3e2c5a */
    color: white;
}

.tale-panel-h1-left{ 
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    float: left;
    width: 95%;
    align-items: center;
    justify-content: center;
   }

.tale-panel-h1-right{ 
    float: right;
    width: 5%;
    display: flex;
    justify-content: right;
    vertical-align: middle;
    font-size: 2rem;
  }

.tale-panel-h1-right :hover {
    color: rgb(238, 154, 45);
    cursor: pointer;
}

.tale-panel {
    display: flex;
}

.tale-panel-left {
    float: left;
    width: 30%;
    padding: 10px;
}

.tale-panel-right {
    float: right;
    width: 70%;
    padding: 10px;
}
