.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: rgb(62, 44, 90);
} 

.header :hover {
    color: rgb(238, 154, 45);
    cursor: pointer;
}

.menu-bars {
    font-size: 2rem;
    margin-left: 2rem;
}

.menu-bars :hover {
    color: rgb(238, 154, 45);
    cursor: pointer;
}

.nav-menu {
    background-color: rgb(62, 44, 90);
    width: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -200px;
    transition: 850ms;
    z-index: 999; 
}

.nav-menu.active {
    left: 0;
    transition: 850ms;
}

.nav-text {
    display: flex;
/*     justify-content: start; */
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
    border: 2px solid rgb(62, 44, 90);
}

.nav-text a:hover {
    color: rgb(238, 154, 45);
    border: 2px solid rgb(238, 154, 45);
}

.nav-menu-items {
    width: 100%;
}

.header-toggle {
    background-color: #3e2c5a;
    width: 100%;
    height: 50px;
    display: flex;
/*    justify-content: start; */
    align-items: center;
}

span {
    margin-left: 20px;
}
/* Path: react-sidebar/src/components/Navbar.js */