.character-panel {
    display: flex;
}

.character-panel-left {
    float: left;
    width: 30%;
    padding: 10px;
}

.character-panel-right {
    float: right;
    width: 70%;
    padding: 10px;
}
