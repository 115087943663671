.tale-selector-h1 {
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #473b5a; /* 3e2c5a */
    color: white;
}

.tale-selector-h1-left{ 
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    float: left;
    width: 95%;
    align-items: center;
    justify-content: center;
   }

.tale-selector-h1-right{ 
    float: right;
    width: 5%;
    display: flex;
    justify-content: right;
    vertical-align: middle;
    font-size: 2rem;
  }

.tale-selector-h1-right :hover {
    color: rgb(238, 154, 45);
    cursor: pointer;
}
  

.tale-selector {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(33%, 1fr));
    padding: 10px;
}

.tale-selector .pics {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

@media (max-width: 960px) {
    .tale-selector {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(50%, 1fr));
    }
}

@media (max-width: 480px) {
    .tale-selector {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(100%, 1fr));
    }
}
