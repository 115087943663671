.img-box {
    position: relative;
}

.img-box img {
    width: 100%;
    opacity: 1.0;
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0);
}

.img-box img:hover {
    border: 2px solid rgb(238, 154, 45);
    opacity: 0.9;
    cursor: pointer;
}
  
.img-box h1 {
    width: 100%;
    position: absolute;
    top: 10%;
    font-size: 20pt;
    text-align: center;
    color:rgb(238, 154, 45);
    text-shadow: rgb(0, 0, 0) 2px 2px 2px;
    cursor: pointer;
}

.img-box h2 {
    width: 90%;
    position: absolute;
    bottom: 5%;
    left: 5%;
    font-size: 16pt;
    text-align: center;
    color:rgb(238, 154, 45);
    text-shadow: rgb(0, 0, 0) 2px 2px 2px;
    cursor: pointer;
}