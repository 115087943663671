.talenavbar {
    height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: rgb(62, 44, 90);
    color: white;
    border-top: 2px solid rgb(238, 154, 45);
}

.talenavbar-left{
    padding-left: 30px;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    float: left;
    width: 95%;
    align-items: center;
    justify-content: center;
}

.talenavbar-breadcrumbs {
    list-style: none;
}

.talenavbar-breadcrumbs li {
    display: inline;
}

.talenavbar-breadcrumbs li:before {
    content:'\0000a0>\0000a0'; /* Non-breaking space */
}

.talenavbar-breadcrumbs li:first-child:before {
    content: normal;
}


.talenavbar-right{ 
    float: right;
    width: 5%;
    display: flex;
    justify-content: right;
    vertical-align: middle;
    font-size: 2rem;
    padding-right: 10px;
  }

.talenavbar-right :hover {
    color: rgb(238, 154, 45);
    cursor: pointer;
}
  
