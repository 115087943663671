@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
  }

.simple-border-img {
    width: 100%;
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0);  
  }
  
  
.hovered-border-img {
  width: 100%;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0);  
}

.hovered-border-img:hover {
  border: 2px solid rgb(238, 154, 45);
}

a {
  color: rgb(211, 122, 5);
}

a:hover  {
  color: rgb(238, 154, 45);
  cursor: pointer;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* min widht in phones */ 
  min-width: 360px;
}

.content-wrap {
  flex: 1;
}
