
.about {
  display: flex;
  align-items: center;
  text-align: center;
}

.about-left{ 
  float: left;
  width: 50%;
  padding: 10px;
  align-items: center;
  justify-content: center;
 }

.about-right{ 
  float: right;
  width: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

