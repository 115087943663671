.footer {
  background-color: #3e2c5a;
  color: white;
  text-align: center;
  padding: 10px;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-menu{
  list-style: none;
}

.footer-menu li{
  display: inline;
  padding: 0 10px;
  border-left: solid 1px white;
}

.footer-menu li:first-child{
  border-left: none;
}