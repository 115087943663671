.character-selector {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(33%, 1fr));
    padding: 10px;
}

.character-selector .pics {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

@media (max-width: 960px) {
    .character-selector {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(50%, 1fr));
    }
}

@media (max-width: 480px) {
    .character-selector {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(100%, 1fr));
    }
}
